import { render, staticRenderFns } from "./Approve.vue?vue&type=template&id=98e7ac1a&"
import script from "./Approve.vue?vue&type=script&lang=ts&"
export * from "./Approve.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QUploader,QDialog,QInnerLoading,QSpinnerGears,QPageSticky,QBtnGroup,QBtn});
